var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ListingLayout',{attrs:{"sub-screen":1,"is-loading":_vm.isLoading,"meta":_vm.meta,"headers":_vm._headers,"items":_vm.list,"bread-crumbs":false,"no-filters":"","show-date":"","date-custom-options":false,"telematic-massage-rows":"","vertical-scroll":""},on:{"filterOption":_vm.onFiltersChange,"closemodal":_vm.closeFilterMenu},scopedSlots:_vm._u([{key:"id",fn:function({ item }){return [(_vm.authUser.tenant_id)?_c('div',[_vm._v(" "+_vm._s(item.id)+" ")]):_c('div',{staticClass:"messageDetail",on:{"click":function($event){return _vm.showMsgDetail(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"device_time",fn:function({ item }){return [_c('v-menu',{attrs:{"id":item.key,"close-on-content-click":false,"nudge-width":150,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"open-link"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.device_time)+" ")])]}}],null,true)},[_c('div',{staticClass:"py-2 px-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('label',{attrs:{"for":""}},[_vm._v("Flespi Server TS")]),_c('span',[_vm._v(_vm._s(item.flespi_server_time ?? "N/A"))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('label',{attrs:{"for":""}},[_vm._v("Server TS")]),_c('span',[_vm._v(_vm._s(item.timestamp ?? "N/A"))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('label',{attrs:{"for":""}},[_vm._v("Pusher Sent At")]),_c('span',[_vm._v(_vm._s(item.pusher_sent_at ?? "N/A"))])])])])]}},{key:"mobile_ack",fn:function({ item }){return [_c('div',[(item.pusher_sent_at && !item.mobile_ack)?_c('span',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-bell-alert ")])]}}],null,true)},[_c('span',[_vm._v("Acknowledgement not recieved")])])],1):(
            item.mobile_ack !== null && item.mobile_ack !== undefined
          )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showMblAck(item.mobile_ack)}}},[_vm._v(" mdi-eye ")]):_c('span',[_vm._v(" N/A")])],1)]}},{key:"position",fn:function({ item }){return [(item.longitude && item.latitude)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showVehiclePosition(item)}}},[_vm._v(" mdi-eye ")]):_c('span',[_vm._v(" N/A")])]}},{key:"problems",fn:function({ item }){return [(item.delay_sync)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("delay_sync_msg"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.problems?.join(", "))+" ")])]}},{key:"addFilters",fn:function(){return [_c('v-card',[_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":100,"content-class":"filterMenDropDown","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"filterDropDown",attrs:{"dense":"","label":"Filters","value":_vm.showSelectedFilters,"readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('v-card',{staticClass:"my-0 py-0"},[_c('v-list',{staticClass:"my-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v("Filters")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":_vm.clearFilters}},[_vm._v(" Clear ")])],1)],1)],1),_c('v-divider'),_c('v-list',_vm._l((_vm.authUser.tenant_id
                    ? _vm.oemfilterOptions
                    : _vm.filterOptions),function(item,i){return _c('v-list-item',{key:i,staticClass:"d-block"},[_c('div',[_c('v-list-item-action',{staticClass:"py-0 my-0"},[_c('v-checkbox',{ref:"myFilterCheck",refInFor:true,attrs:{"label":item.name,"value":item.id,"hide-details":"","ripple":false},on:{"change":function($event){_vm.onFilterChange(
                            item.id,
                            _vm.selectedFilter.includes(item.id)
                          )}},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1),(
                        item.id == 'interval' &&
                          _vm.selectedFilter.includes(item.id)
                      )?_c('v-list-item-content',{staticClass:"py-0"},[_c('v-select',{attrs:{"items":_vm.intervalOption,"item-text":"label","item-value":"value","dense":""},on:{"change":_vm.onTimeIntervalChange},model:{value:(_vm.customFilter.interval),callback:function ($$v) {_vm.$set(_vm.customFilter, "interval", $$v)},expression:"customFilter.interval"}})],1):_vm._e(),(
                        item.id == 'soc' && _vm.selectedFilter.includes(item.id)
                      )?_c('v-list-item-content',{staticClass:"py-0 my-0"},[_c('div',{staticClass:"SOC-Options"},[_c('div',[_c('v-select',{staticClass:"py-0 pt-2",attrs:{"items":[
                              { value: '=', title: '=' },
                              { value: '>', title: '>' },
                              { value: '<', title: '<' },
                            ],"item-text":"title","item-value":"value"},on:{"change":_vm.onChangeSOC},model:{value:(_vm.isSOC.operator),callback:function ($$v) {_vm.$set(_vm.isSOC, "operator", $$v)},expression:"isSOC.operator"}})],1),_c('div',[_c('v-text-field',{staticClass:"py-0 pt-2",attrs:{"label":_vm.$t('state_of_charge'),"type":"number","min":"0","max":"100","suffix":"%"},on:{"change":_vm.onChangeSOC},model:{value:(_vm.isSOC.value),callback:function ($$v) {_vm.$set(_vm.isSOC, "value", $$v)},expression:"isSOC.value"}})],1)])]):_vm._e(),(
                        item.id == 'ignition ' &&
                          _vm.selectedFilter.includes(item.id)
                      )?_c('v-list-item-content',{staticClass:"py-0 my-0"},[_c('v-select',{attrs:{"items":[
                          { value: 0, title: 'OFF' },
                          { value: 1, title: 'ON' },
                        ],"item-text":"title","item-value":"value","dense":""},on:{"change":_vm.onChangeIgnition},model:{value:(_vm.customFilter.ingnition),callback:function ($$v) {_vm.$set(_vm.customFilter, "ingnition", $$v)},expression:"customFilter.ingnition"}})],1):_vm._e(),(
                        item.id == 'latency' &&
                          _vm.selectedFilter.includes(item.id)
                      )?_c('v-list-item-content',{staticClass:"py-0 my-0"},[_c('div',{staticClass:"Latency-Options"},[_c('div',[_c('v-select',{staticClass:"py-0 pt-2",attrs:{"items":[
                              { value: '=', title: '=' },
                              { value: '>', title: '>' },
                              { value: '<', title: '<' },
                            ],"item-text":"title","item-value":"value"},on:{"change":_vm.onChangeLatency},model:{value:(_vm.isLatency.operator),callback:function ($$v) {_vm.$set(_vm.isLatency, "operator", $$v)},expression:"isLatency.operator"}})],1),_c('div',[_c('v-text-field',{staticClass:"py-0 pt-2",attrs:{"label":_vm.$t('latency'),"type":"number","min":"0","max":"100"},on:{"change":_vm.onChangeLatency},model:{value:(_vm.isLatency.value),callback:function ($$v) {_vm.$set(_vm.isLatency, "value", $$v)},expression:"isLatency.value"}})],1),_c('div',[_c('v-select',{staticClass:"py-0 pt-2",attrs:{"items":[
                              { value: 'h', title: 'Hrs' },
                              { value: 'm', title: 'Min' },
                              { value: 's', title: 'Sec' },
                            ],"item-text":"title","item-value":"value"},on:{"change":_vm.onChangeLatency},model:{value:(_vm.isLatency.unit),callback:function ($$v) {_vm.$set(_vm.isLatency, "unit", $$v)},expression:"isLatency.unit"}})],1)])]):_vm._e()],1)])}),1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.filterMenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)])]},proxy:true},{key:"actionModals",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[(_vm.selectedHeaders.columns !== null)?_c('div',[_c('v-menu',{attrs:{"left":"","close-on-content-click":false,"offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune-vertical")])],1)]}}],null,false,3540552150),model:{value:(_vm.isShowHeaderFilter),callback:function ($$v) {_vm.isShowHeaderFilter=$$v},expression:"isShowHeaderFilter"}},[(_vm.isShowHeaderFilter)?_c('div',[(_vm.selectedHeaders.columns !== null)?_c('table-head-filters',{attrs:{"items":_vm.headersList},on:{"addColumns":_vm.fetchHeaders}}):_vm._e()],1):_vm._e()])],1):_vm._e(),(_vm.list.length > 0)?_c('div',[_c('refresh-button',{attrs:{"is-loading":_vm.isLoading},on:{"reload":_vm.fetchData}})],1):_vm._e(),(_vm.isModalOpen)?_c('message-detail-modal',{attrs:{"is-visible-dialog":_vm.isModalOpen,"data":_vm.telematicJSON},on:{"close":_vm.closeModal}}):_vm._e(),(_vm.positionModal)?_c('vehicle-position-modal',{attrs:{"id":0,"is-visible-dialog":_vm.positionModal,"toggle-modal":_vm.togglePositionModal,"position":_vm.selectedPosition}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }